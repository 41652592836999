/* تحديد الخطوط المحلية باستخدام @font-face */
@font-face {
  font-family: 'TheSansArabic';
  src: url('./assets/fonts/TheSansArabic-Light.otf') format('opentype');
  font-weight: 300; /* للوزن الخفيف */
  font-style: normal;
}

@font-face {
  font-family: 'TheSansArabic';
  src: url('./assets/fonts/THESANSARABIC-BOLD.ttf') format('truetype');
  font-weight: 700; /* للوزن الغامق */
  font-style: normal;
}

  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  /* // swiper bundle styles */
  @import "swiper/css";
  
  /* // modules styles */
  @import "swiper/css/navigation";
  @import "swiper/css/pagination";
  @import "swiper/css/effect-coverflow";
  @import "swiper/css/free-mode";
  @import "swiper/css/thumbs";
  
  /* تطبيق الخط على النصوص بالعربية والانجليزية */
  *:lang(ar) {
    font-family: "TheSansArabic", sans-serif !important;
  }
  
  *:lang(en) {
    font-family: "TheSansArabic", sans-serif !important;
  }
  
  body {
    overflow-x: hidden;
  }
  